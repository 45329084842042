import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import { Transition } from "@headlessui/react"
import DrawerMenu from "./DrawerMenu"

const Header = () => {
  const [isExpanded, toggleExpansion] = useState(false)
  const toggleMenu = () => {
    toggleExpansion(!isExpanded)
  }

  const menuList = [
    { text: "noone", path: `/noone/` },
    { text: "one", path: `/one/` },
    { text: "nico", path: `/nico/` },
    { text: "Do", path: `/do/` },
    { text: "Do CAFE", path: `/do-cafe/` },
  ]

  return (
    <nav>
      <div className="flex flex-wrap items-center justify-between p-5 bg-top bg-repeat-round">
        <div className="flex items-center flex-shrink-0 mr-6 text-black">
          <span>
            <StaticImage
              src="../../images/top/noone.png"
              alt="noone logo"
              layout="fixed"
              width={50}
              className="text-xl font-semibold tracking-tight"
            />
          </span>
          <div className="ml-4 text-xl font-kiwiMaru">NOONE</div>
        </div>
        <div className="hidden space-x-10 md:flex">
          {menuList.map(item => {
            return (
              <Link key={item.path} to={item.path} className="font-kiwiMaru">
                {item.text}
              </Link>
            )
          })}
        </div>
        <button
          className="z-20 hamburger md:hidden"
          id="hamburger1"
          onClick={toggleMenu}
        >
          <span className={`line ${isExpanded ? "line1-active" : ""}`}></span>
          <span className={`line ${isExpanded ? "line2-active" : ""}`}></span>
          <span className={`line ${isExpanded ? "line3-active" : ""}`}></span>
        </button>
        <Transition
          show={isExpanded}
          enter="transition ease-in-out duration-500 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-500 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
          className="fixed top-0 z-10 w-3/5 h-screen px-12 py-6 bg-white -right-5"
        >
          <DrawerMenu toggleMenu={toggleMenu} items={menuList} />
        </Transition>
      </div>
      <div
        className={`h-4 rotate-180 bg-repeat-x md:h-8 bg-topWaveSp md:bg-topWave`}
      ></div>
    </nav>
  )
}

export default Header
