import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import HeadingM from "../shared/HeadingM"
import ProjectCard from "./ProjectCard"

const query = graphql`
  query {
    nooneLogo: file(relativePath: { eq: "noone/logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 50)
      }
    }
    oneLogo: file(relativePath: { eq: "one/logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 40)
      }
    }
    nicoLogo: file(relativePath: { eq: "nico/logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 50)
      }
    }
    doLogo: file(relativePath: { eq: "do/logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 50)
      }
    }
    doCafeLogo: file(relativePath: { eq: "do-cafe/logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 40)
      }
    }
  }
`

const Projects = () => {
  const data = useStaticQuery(query)
  const projectList = [
    {
      logo: getImage(data.nooneLogo),
      title: "noone",
      area: "埼玉県熊谷市",
      type: "児童発達支援・放課後等デイサービス",
      path: "noone",
    },
    {
      logo: getImage(data.oneLogo),
      title: "one",
      area: "群馬県沼田市",
      type: "児童発達支援・放課後等デイサービス",
      path: "one",
    },
    {
      logo: getImage(data.nicoLogo),
      title: "nico",
      area: "群馬県沼田市",
      type: "放課後等デイサービス",
      path: "nico",
    },
    {
      logo: getImage(data.doLogo),
      title: "Do",
      area: "群馬県沼田市",
      type: "生活介護・就労支援B型事業所",
      path: "do",
    },
    {
      logo: getImage(data.doCafeLogo),
      title: "Do CAFE",
      area: "群馬県沼田市",
      type: "就労支援カフェ",
      path: "do-cafe",
    },
  ]

  return (
    <div className="mt-24">
      <HeadingM title="事業一覧" target="top" />
      <div className="mt-12 text-center md:mt-16">
        これらの課題解決に取り組むことは、私たちの責務であると考えています。
        <br className="hidden md:block" />
        決して、傍観者にはなりたくありません。足りない社会資源は創っていくことが必要です。
        <br />
        <br />
        しかしながら、私達のみで出来ることには限りがあります。一人で創出していくことはできません。
        <br className="hidden md:block" />
        だからこそ、私たちは様々な人たちの協力を得て事業の発展を目指します。
      </div>
      <div className="grid-cols-2 gap-10 mt-16 space-y-6 md:grid md:space-y-0">
        {projectList.map(item => (
          <ProjectCard key={item.title} {...item} />
        ))}
      </div>
    </div>
  )
}

export default Projects
